import { useFormContext } from "react-hook-form-new";
import { useEffect } from "react";

export default function Checkbox({options, label, defaultValue = {}}) {
  const { register, setValue } = useFormContext();

  useEffect(() => {
    options.forEach(option => {
      const defaultVal = defaultValue[option.label];
      if (defaultVal !== undefined) {
        setValue(option.label, defaultVal);
      }
    });
  }, []);

  return ( <fieldset>
      {label && <legend className="text-sm font-semibold leading-6 text-gray-900">{label}</legend>}
      <div className="mt-6 space-y-6 sm:flex sm:items-center sm:space-x-10 sm:space-y-0">
        {options.map((option, idx) =>
          <div key={idx} className="relative flex gap-x-3">
            <div className="flex h-6 items-center">
              <input
                id={option.label}
                {...register(option.label)}
                type="checkbox"
                className="h-4 w-4 rounded border-gray-300 text-primary focus:ring-primary"
                defaultChecked={defaultValue[option.label]}
              />
            </div>
            <div className="text-sm leading-6">
              <label htmlFor={option.label} className="font-medium text-gray-900">
                {option.label}
              </label>
            </div>
          </div>
        )}
      </div>
    </fieldset>
  )
}
