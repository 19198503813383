import "./css/style.css";

import {useEffect} from "react";
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import {Provider} from "react-redux";

import {store} from "./store";

import jwt_decode from "jwt-decode";
import setAuthToken from "./lib/setAuthToken";
import checkForExpiredToken from "./lib/checkForExpiredToken";
import {setCurrentUser} from "./actions/authActions";

import "./lib/axiosInterceptor";
import {Authorization} from "./lib/Authorization";
import PrivateRoute from "./components/PrivateRoute";
import Notifications from "./components/Notifications";

import Home from "./pages/Home";
import Kontakt from "./pages/Kontakt";
import Login from "./pages/Login";
import Register from "./pages/Register";
import RegisterForm from "./pages/RegisterForm";

import Forgot from "./pages/Forgot";
import Reset from "./pages/Reset";
import NeuesPasswort from "./pages/NeuesPasswort";
import NotFound from "./pages/NotFound";

// PRIVATE ROUTES
import Dashboard from "./pages/Dashboard";
import Angebot from "./pages/Angebot";
import Angebote from "./pages/Angebote";
import Checkout from "./pages/Checkout";

// USER ROUTES
import Profile from "./pages/Profile";
import Contracts from "./pages/Contracts";
import Quality from "./pages/Quality";
import QualityChart from "./pages/QualityChart";
import QualityDetails from "./pages/QualityDetails";
import BatchData from "./pages/BatchData";
import MeineAngebote from "./pages/MeineAngebote";
import Estimates from "./pages/Estimates";
import Betrieb from "./pages/Betrieb";
import Links from "./pages/Links";
import Messages from "./pages/Messages";
import Neuigkeiten from "./pages/Neuigkeiten";
import Downloads from "./pages/Downloads";
import KontaktEinkauf from "./pages/KontaktEinkauf";
import Invoices from "./pages/Invoices";

// ADMIN ROUTES
import AdminAngebote from "./pages/admin/Angebote";
import AdminSchätzungen from "./pages/admin/Estimates";
import SchätzungenEdit from "./pages/admin/EstimatesEdit";
import AdminNews from "./pages/admin/News";
import NewsEdit from "./pages/admin/NewsEdit";
import Mittelwerte from "./pages/admin/Mittelwerte";
import MittelwerteEdit from "./pages/admin/MittelwerteEdit";
import AdminLinks from "./pages/admin/Links";
import LinkEdit from "./pages/admin/LinkEdit";
import AdminKonto from "./pages/admin/Konto";
import KontoEdit from "./pages/admin/KontoEdit";
import Email from "./pages/admin/Email";
import EmailEdit from "./pages/admin/EmailEdit";
import System from "./pages/admin/System";
import Einkauf from "./pages/admin/Einkauf";
import EinkaufEdit from "./pages/admin/EinkaufEdit";
import Formulare from "./pages/admin/Formulare";
import FormulareEdit from "./pages/admin/FormulareEdit";
import Grower from "./pages/admin/Grower";
import Upload from "./pages/admin/Upload";
import Newsletter from "./pages/admin/Newsletter";
import PriceList from "./pages/admin/PriceList";
import PriceListEdit from "./pages/admin/PriceListEdit";
import Uvm from "./pages/admin/Uvm";
import UvmEdit from "./pages/admin/UvmEdit";

// Check for token
if (localStorage.jwtToken) {
  // Set auth token header auth
  setAuthToken(localStorage.jwtToken);
  // Decode token and get user info and exp
  const decoded = jwt_decode(localStorage.jwtToken);
  // Set user and isAuthenticated
  store.dispatch(setCurrentUser(decoded));
// Check for expired token
  checkForExpiredToken(decoded);
}

function App() {
  // User Groups
  const Admin = Authorization(["admin"]);
  const Salesman = Authorization(["admin", "salesman"]);

  useEffect(() => {
    const interval = setInterval(() => {
      const decoded = jwt_decode(localStorage.jwtToken);
      checkForExpiredToken(decoded);
    }, 360000);
    return () => clearInterval(interval);
  }, []);

  return (
    <>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/kontakt" component={Kontakt} />
        <Route exact path="/login" component={Login} />
        <Route exact path="/forgot" component={Forgot} />
        <Route exact path="/register" component={Register} />
        <Route exact path="/register/:token" component={RegisterForm} />
        <Route exact path="/reset/:token" component={Reset} />
        <Route exact path="/kontakt" component={Kontakt} />

        {/* USER ROUTES */}
        <PrivateRoute path="/neues-passwort" component={NeuesPasswort} />
        <PrivateRoute path="/angebot" component={Angebot} />
        <PrivateRoute path="/angebote" component={Angebote} />
        <PrivateRoute path="/abschluss" component={Checkout} />

        {/* USER ROUTES */}
        <PrivateRoute exact path="/dashboard" component={Dashboard} />
        <PrivateRoute path="/konto" component={Profile} />
        <PrivateRoute path="/verträge" component={Contracts} />
        <PrivateRoute path="/partiedaten" component={BatchData} />
        <PrivateRoute path="/schätzungen" component={Estimates} />
        <PrivateRoute path="/meine-angebote" component={MeineAngebote} />
        <PrivateRoute exact path="/qualität" component={Quality} />
        <PrivateRoute exact path="/qualität/chart" component={QualityChart} />
        <PrivateRoute exact path="/qualität/details" component={QualityDetails} />
        <PrivateRoute path="/betrieb" component={Betrieb} />
        <PrivateRoute path="/nachrichten" component={Messages} />
        <PrivateRoute path="/neuigkeiten" component={Neuigkeiten} />
        <PrivateRoute path="/downloads" component={Downloads} />
        <PrivateRoute path="/links" component={Links} />
        <PrivateRoute path="/kontakt-einkauf" component={KontaktEinkauf} />
        <PrivateRoute path="/abrechnungen" component={Invoices} />

        {/* Einkäufer ROUTES */}
        <PrivateRoute path="/pflanzer" component={Salesman(Grower)} />

        {/* ADMIN ROUTES */}
        <PrivateRoute path="/admin/angebote" component={Admin(AdminAngebote)} />
        <PrivateRoute path="/admin/konten" component={Admin(AdminKonto)} />
        <PrivateRoute path="/admin/konto/:id" component={Admin(KontoEdit)} />
        <PrivateRoute exact path="/admin/email" component={Admin(Email)} />
        <PrivateRoute path="/admin/email/:id" component={Admin(EmailEdit)} />
        <PrivateRoute path="/admin/system" component={Admin(System)} />
        <PrivateRoute path="/admin/upload" component={Admin(Upload)} />
        <PrivateRoute path="/admin/newsletter" component={Admin(Newsletter)} />

        <PrivateRoute exact path="/admin/einkauf" component={Admin(Einkauf)} />
        <PrivateRoute path="/admin/einkauf/:id" component={Admin(EinkaufEdit)} />
        <PrivateRoute exact path="/admin/formulare" component={Admin(Formulare)} />
        <PrivateRoute path="/admin/formulare/:id" component={Admin(FormulareEdit)} />
        <Route exact path="/admin/links" component={AdminLinks} />
        <PrivateRoute path="/admin/links/:id" component={Admin(LinkEdit)} />
        <PrivateRoute exact path="/admin/neuigkeiten" component={Admin(AdminNews)} />
        <PrivateRoute path="/admin/neuigkeiten/:id" component={Admin(NewsEdit)} />
        <PrivateRoute exact path="/admin/mittelwerte" component={Admin(Mittelwerte)} />
        <PrivateRoute path="/admin/mittelwerte/:id" component={Admin(MittelwerteEdit)} />
        <PrivateRoute exact path="/admin/schätzungen" component={Admin(AdminSchätzungen)} />
        <PrivateRoute path="/admin/schätzungen/:id" component={Admin(SchätzungenEdit)} />

        <PrivateRoute exact path="/admin/preislisten" component={Admin(PriceList)} />
        <PrivateRoute path="/admin/preislisten/:id" component={Admin(PriceListEdit)} />
        <PrivateRoute exact path="/admin/uvm" component={Admin(Uvm)} />
        <PrivateRoute path="/admin/uvm/:id" component={Admin(UvmEdit)} />

        <Route component={NotFound} />
      </Switch>
      <Notifications />
    </>
  );
}

// eslint-disable-next-line
export default () => (
  <Provider store={store}>
    <Router>
      <App />
    </Router>
  </Provider>
);
