import {currency} from "./currency";
import {getHopType} from "./getHopType";

export function getFixedPrice(offer) {
  const type = getHopType(offer);

  return type === " kg Rohhopfen"
    ? currency(offer["Preis Kg"])
    : currency(offer["Preis Kg Alpha"]);
}
