import {useFormContext} from "react-hook-form-new";

export default function Radio({ options, name, defaultValue, required, label }) {
  const { register } = useFormContext();

  return (
    <fieldset>
      <legend className="text-sm font-semibold leading-6 text-gray-900">{label}</legend>
      <div className="mt-6 space-y-6 sm:flex sm:items-center sm:space-x-10 sm:space-y-0">
        {options.map((option, idx) => (
          <div key={idx} className="flex grow items-center">
            <input
              {...register(name, { required: required })}
              defaultChecked={option.value === defaultValue}
              value={option.value}
              id={option.label}
              type="radio"
              className="h-4 w-4 border-gray-300 text-primary focus:ring-primary"
            />
            <label htmlFor={option.label} className="ml-3 block text-sm font-medium leading-6 text-gray-900">
              {option.label}
            </label>
          </div>
        ))}
      </div>
    </fieldset>
  )
}
