import axios from "axios";

import {useForm, FormProvider} from "react-hook-form";
import useSystem from "../../hooks/useSystem";
import useLieferung from "../../hooks/useLieferung";

import {calcSum} from "../../lib/calcSum";
import {formatKilo} from "../../lib/formatKilo";
import {getHopType} from "../../lib/getHopType";
import {getFixedPrice} from "../../lib/getFixedPrice";
import {formatAVHLV} from "../../lib/formatAVHLV";

import AGB from "../Angebote/AGB";
import Zusatzvereinbarungen from "../Angebote/Zusatzvereinbarungen";

export default function Check({ session, items, setSuccess }) {
  const methods = useForm();
  const system = useSystem();
  const { data, angebot } = items;
  const url = `angebot?vertragsart=${angebot.Vertragsart}&jahr=${angebot.Startjahr}&sorte=${angebot.Sorte}&id=${angebot.id}`;
  const lieferung = useLieferung(angebot.Sorte);

  const type = getHopType(angebot);
  const price = getFixedPrice(angebot);
  const unit = type === " kg Rohhopfen" ? "kg" : "kg Alpha";
  const { cropYear } = useSystem();
  const raw = type === " kg Rohhopfen";
  const alphaStart = angebot["Alpha von"] > 0;
  const quality = angebot["Qualität"];

  const buyerWeight = data.weight ? data.weight.filter((w) => w !== true) : 0;

  const menge =
    Number(data.freihopfen) === 0
      ? Number(data.estimate)
      : Number(data.freihopfen) === 1
        ? calcSum(data.poolamount, lieferung, data.überlieferung)
        : Number(data.freihopfen) === 2 && Number(lieferung);

  const onSubmit = async(values) => {
    const formValues = {
      angebot: items.data,
      params: items.angebot,
      menge: menge,
      preis: price,
      type: type,
      lieferung:
        items.data.überlieferung || Number(items.data.freihopfen) === 2
          ? lieferung
          : 0,
      abwaage:
        Number(items.data.freihopfen) === 0 || buyerWeight.length > 0 ? 1 : 0,
    };

    await axios.post("/api/contracts/pool", formValues);
    setSuccess(true);
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <div className="bg-white mt-6 shadow overflow-hidden sm:rounded-lg">
          <div className="px-4 py-5 sm:px-6">
            <h3 className="font-display text-3xl uppercase text-gray-900">
              Ihr Vertragsangebot prüfen
            </h3>
          </div>
          <div className="border-t border-primary px-4 py-5 sm:p-0">
            <dl className="sm:divide-y sm:divide-gray-200">
              <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">Sorte</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  {angebot.hop.Beschreibung}
                </dd>
              </div>
              <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">
                  Vertragsmenge
                </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  {Number(data.freihopfen) === 0 ? "ca. " : ""}
                  {formatKilo(menge)} kg
                </dd>
              </div>
              <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">Erntejahr</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  {cropYear}
                </dd>
              </div>
              <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">
                  Anzahlungspreis € / {unit}
                </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  {price} {price === '0,00 €' && "(vorerst ohne Anzahlung)"}
                </dd>
              </div>
            </dl>
          </div>
          <div className="border-t border-primary ">
            {data.überlieferung || data.freihopfen === "2" ? (
              <>
                <dl className="sm:divide-y sm:divide-gray-200">
                  <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt className="text-sm font-medium text-gray-500">
                      Die Liefermenge stammt aus:
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2"></dd>
                  </div>
                </dl>
                <div className="relative border p-4 flex flex-col cursor-pointer md:pl-4 md:pr-6 md:grid md:grid-cols-2 focus:outline-none">
                  <label>
                    <input
                      className="text-primary"
                      type="radio"
                      checked={true}
                      readOnly
                    />
                    <span className="ml-4 font-medium"> Überlieferung</span>
                  </label>
                </div>
              </>
            ) : (
              ""
            )}

            {data.freihopfen !== "2" ? (
              <dl className="sm:divide-y sm:divide-gray-200">
                <dt className="py-4 sm:px-6 text-sm font-medium text-gray-500">
                  Die Abwaage des Hopfens erfolgt / erfolgte auf
                </dt>
                {buyerWeight.length > 0 || data.freihopfen === "0" ? (
                  <div className="relative border p-4 flex flex-col cursor-pointer md:pl-4 md:pr-6 md:grid md:grid-cols-2 focus:outline-none">
                    <label>
                      <input
                        className="text-primary"
                        type="radio"
                        checked={true}
                        readOnly
                      />
                      <span className="ml-4 font-medium">
                        Namen des Käufers
                      </span>
                    </label>
                  </div>
                ) : (
                  ""
                )}
                {buyerWeight.length > 0 &&
                  data.weight.map((ballen, idx) =>
                    ballen !== true ? (
                      <div
                        key={idx}
                        className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
                      >
                        <dt className="text-sm font-medium text-gray-500">
                          {idx + 1}. Partie
                        </dt>
                      </div>
                    ) : (
                      ""
                    )
                  )}

                {data.partie ? (
                  <div className="relative border p-4 flex flex-col cursor-pointer md:pl-4 md:pr-6 md:grid md:grid-cols-2 focus:outline-none">
                    <label>
                      <input
                        className="text-primary"
                        type="radio"
                        checked={true}
                        readOnly
                      />
                      <span className="ml-4 font-medium">
                        "Käufer unbekannt"
                      </span>
                    </label>
                  </div>
                ) : (
                  ""
                )}
                {data.partie &&
                  data.partie.length > 0 &&
                  data.partie.map((ballen, idx) => (
                    <div
                      key={idx}
                      className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
                    >
                      <dt className="text-sm font-medium text-gray-500">
                        Partie {idx + 1} - 1. Ballennummer:
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        {ballen}
                      </dd>
                    </div>
                  ))}
              </dl>
            ) : (
              ""
            )}
          </div>
          <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
            <div className="sm:col-span-2">
              <p className="mt-1  text-gray-800">
                Der Verkäufer liefert{" "}
                <b>
                  {Number(data.freihopfen) === 0 ? "ca. " : ""}
                  {formatKilo(menge)}
                </b>{" "}
                kg {!raw ? "Rohhopfen" : ""} seiner selbst erzeugten und nicht
                vertragsgebundenen Hopfen der oben genannten Sorte amtlich
                bezeichnet und in einwandfreier Qualität an den Käufer.
              </p>
              <p className="mt-4  text-gray-800">
                Der Verkäufer ist damit einverstanden, dass die Vermarktung
                (Verarbeitung und Weiterverkauf an Dritte) unwiderruflich durch
                den Käufer vorgenommen wird.
              </p>
              <p className="mt-4  text-gray-800">
                Im Gegenzug garantiert der Käufer dem Verkäufer die Abnahme und
                bestmögliche Vermarktung dieser Hopfen.
              </p>
              <p className="mt-4  text-gray-800">
                Der Käufer leistet spätestens 30 Werktage nach dem Zeitpunkt der
                Übergabe der Hopfen eine Anzahlung in Höhe von{" "}
                {price === '0,00 €' ? <b>vorerst ohne Anzahlung.</b> : <b>{price}/{unit}.</b>}
              </p>
            </div>
          </div>
        </div>

        <div className="bg-white mt-6 shadow overflow-hidden sm:rounded-lg">
          <div className="px-4 py-5 sm:px-6">
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              Angaben zum Pool-Hopfen
            </h3>
          </div>
          <div className="border-t border-primary px-4 py-5 sm:px-6">
            <div className="sm:col-span-2">
              {raw && (
                <p>
                  Für die Ermittlung des maßgeblichen Gesamtkaufpreises
                  (Anzahlungspreis und Nachzahlungspreis inklusive Zu- und
                  Abschläge) wird
                </p>
              )}

              {Boolean(raw && alphaStart && quality === 0) && (
                <div className="relative p-2 cursor-pointer  focus:outline-none">
                  <label>
                    <input
                      className="text-primary"
                      name="alphapreis"
                      type="radio"
                      checked
                      readOnly
                    />
                    <span className="ml-4">
                      die Alphatabelle für Freihopfen mit den dortigen Zu- und
                      Abschlägen sowie zusätzlich die Qualitäts­tabelle für
                      deutschen Siegelhopfen ohne Geltung von Zu- und Abschlägen
                      zu Grunde gelegt.
                    </span>
                  </label>
                </div>
              )}
              {Boolean(raw && !alphaStart && quality === 1) && (
                <div className="relative p-2 cursor-pointer  focus:outline-none">
                  <label>
                    <input
                      className="text-primary"
                      name="alphapreis"
                      type="radio"
                      checked
                      readOnly
                    />
                    <span className="ml-4">
                      <b>die Qualitätstabelle</b> für
                      deutschen Siegelhopfen <b>mit</b> Geltung der dort festgelegten
                      Zu- und Abschläge in Höhe von jeweils 50% zu Grunde gelegt.
                    </span>
                  </label>
                </div>
              )}
              {Boolean(raw && alphaStart && quality === 1) && (
                <div className="relative p-2 cursor-pointer  focus:outline-none">
                  <label>
                    <input
                      className="text-primary"
                      name="alphapreis"
                      type="radio"
                      checked
                    />
                    <span className="ml-4">
                      <b>die Alphatabelle</b> für Freihopfen mit den dortigen
                      Zu- und Abschlägen sowie{" "}
                      <b>zusätzlich die Qualitätstabelle</b> für deutschen
                      Siegelhopfen mit Geltung der dort festgelegten Zu- und
                      Abschläge in Höhe von jeweils 50% zu Grunde gelegt.
                    </span>
                  </label>
                </div>
              )}
              {Boolean(raw && alphaStart && quality === 2) && (
                <div className="relative p-2 cursor-pointer  focus:outline-none">
                  <label>
                    <input
                      className="text-primary"
                      name="alphapreis"
                      type="radio"
                      checked
                    />
                    <span className="ml-4">
                      die <b>Alphatabelle</b> für Freihopfen mit den dortigen
                      Zu- und Abschlägen sowie{" "}
                      <b>zusätzlich die Qualitätstabelle</b> für deutschen
                      Siegelhopfen <b>mit</b> Geltung der dort festgelegten Zu-
                      und Abschläge in Höhe von jeweils 100% zu Grunde gelegt.
                    </span>
                  </label>
                </div>
              )}


              {Boolean((raw && alphaStart && quality === 0) || (raw && !alphaStart && quality === 0)) && (
                  <div className="relative p-2 cursor-pointer  focus:outline-none">
                    <label>
                      <input
                        className="text-primary"
                        name="alphapreis"
                        type="radio"
                        checked
                        readOnly
                      />
                      <span className="ml-4">
                      die Qualitätstabelle ohne Geltung von Zu- und Abschlägen zu Grunde gelegt.
                    </span>
                    </label>
                  </div>
              )}
              {Boolean(raw && !alphaStart && quality === 2) && (
                <div className="relative p-2 cursor-pointer  focus:outline-none">
                  <label>
                    <input
                      className="text-primary"
                      name="alphapreis"
                      type="radio"
                      checked
                      readOnly
                    />
                    <span className="ml-4">
                      ausschließlich die Qualitätstabelle für deutschen
                      Siegelhopfen (AVHLV, Anhang III) zu Grunde gelegt.
                    </span>
                  </label>
                </div>
              )}
              <p>
                Die Endabrechnung wird bis zum 31.07. des auf die jeweilige
                Ernte folgenden Jahres durchgeführt. Sollte der für die
                jeweilige Ernte und der nach diesem Vertrag gelieferten
                Hopfensorte erzielte durchschnittliche Freihopfenpreis des
                Käufers im jeweiligen Anbaugebiet über dem nach diesem Vertrag
                bezahlten Anzahlungspreis liegen, erhält der Verkäufer mit der
                Endabrechnung eine Nachzahlung über den Anzahlungspreis hinaus,
                deren Höhe nach erfolgter Vermarktung durch den Käufer bestimmt
                wird.
              </p>
              <br />
              <p>
                Im gegenseitigen Einvernehmen kann dieser Vertrag auch vor
                diesem Termin in einen Freikauf umgewandelt werden.
              </p>
              <br />
              <p>
                Für die Ermittlung des Alphawertes wird das nach den
                Vorschriften der „Allgemeinen Vereinbarungen zum
                Hopfenlieferungsvertrag“ {formatAVHLV(system.AVHLV)}, Ziffer V.
                für die neutrale Qualitätsfeststellung gezogene Probemuster
                untersucht. Die Ermittlung des Alphawertes erfolgt zum Zeitpunkt
                der Übernahme nach der Methode Analytica EBC 7.4 durch das
                Untersuchungslabor HHV Au, Mainburgerstr. 26, 84072
                Au/Hallertau.
              </p>
              <br />
              <p>
                Sofern eine Alphasäure Untersuchung im Rahmen der
                „Verbandsvereinbarung zum Hopfengeschäftsverkehr Deutscher
                Siegelhopfen“ in Auftrag gegeben wurde, dann wird
                der Alphawert nach der in der Vereinbarung beschriebenen
                Vorgehensweise ermittelt und zugrunde gelegt.
              </p>
              <br />
              <p>
                Die gesamte, an den Käufer zu liefernde Menge dieser Sorte ist
                möglichst in einer Partie und in einer Pflanzenschutzmittel –
                Norm zu liefern.
              </p>
              <br />
              <p>
                Änderungen und Ergänzungen dieser Vereinbarung bedürfen der
                Schriftform.
              </p>
              <br />
              <p>
                Diesem Vertrag liegen in der nachfolgenden Reihenfolge folgende
                Bestimmungen zu Grunde:
              </p>
              <ul className="ml-4 mt-4">
                <li>a) Die Bestimmungen dieses Übernahmevertrags.</li>
                <li>b) Die Vertragsangebotsbestimmungen</li>
                <li>
                  c) Die „Allgemeinen Vereinbarungen zum
                  Hopfenlieferungsvertrag“ - {formatAVHLV(system.AVHLV)}
                </li>
                <li>
                  d) Die gesetzlichen Bestimmungen, insbesondere die
                  Bestimmungen des BGB.
                </li>
                <li>
                  e) Die gesetzlichen Bestimmungen zur amtlichen Bezeichnung von
                  Hopfen.
                </li>
              </ul>
            </div>
          </div>
        </div>

        <Zusatzvereinbarungen title="Zusatzvereinbarungen" angebot={angebot} />
        <AGB />
        <div className="pt-5">
          <div className="flex justify-end">
            <a
              href={url}
              className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-secondary"
            >
              Ihr Angebot korrigieren
            </a>
            <button
              type="submit"
              className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-primary hover:bg-secondary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-secondary"
            >
              Ihr Vertragsangebot verbindlich absenden
            </button>
          </div>
        </div>
      </form>
    </FormProvider>
  );
}
