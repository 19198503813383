export default function formatQuality(contract) {
  switch (contract) {
    case 2:
      return "Volle Qualität";
    case 1:
      return "50% Qualität";
    case 0:
      return "Ohne Qualität";
    default:
      return "-"
  }
}
