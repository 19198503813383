import {Controller, useFormContext} from "react-hook-form-new";
import ReactSelect from "react-select";
import {classNames} from "../../../lib/classNames";
import React, {useEffect} from "react";
import {isEmpty} from "../../../lib/isEmpty";


export default function SelectInput({ name, label, options, defaultValue, grid, error }) {
  const { control, setValue } = useFormContext();

  useEffect(() => {
    if (!isEmpty(defaultValue)) {
      setValue(name, defaultValue)
    }
  }, [name, setValue, defaultValue]);

  return (
    <div className={classNames(grid ? grid : 'col-span-full', "mt-2")}>
      <label className="block text-sm font-medium text-gray-700 mb-2">{label}</label>
      <Controller
        control={control}
        rules={{ required: true }}
        defaultValue={options.map((c) => c.value)}
        name={name}
        render={({ field: { onChange, value, ref } }) => (
          <ReactSelect
            placeholder="Auswählen..."
            inputRef={ref}
            defaultValue={options.find((c) => c.value === defaultValue)}
            onChange={(val) => onChange(val.value)}
            options={options}
            required
          />
        )}
      />
      <p className="text-sm text-red-500 mt-2">{error}</p>
    </div>
  );
}
