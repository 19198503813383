import React, {useEffect, useState} from 'react';
import Select from 'react-select';
import {Controller} from 'react-hook-form';

const customStyles = {
  control: (provided) => ({
    ...provided,
    backgroundColor: 'white',
    borderColor: '#d1d5db', // Tailwind's gray-300
    padding: '0.5rem',
    borderRadius: '0.375rem', // Tailwind's rounded-md
  }),
  multiValue: (provided) => ({
    ...provided,
    backgroundColor: '#e5e7eb', // Tailwind's gray-200
  }),
  multiValueLabel: (provided) => ({
    ...provided,
    color: '#374151', // Tailwind's gray-700
  }),
  multiValueRemove: (provided) => ({
    ...provided,
    color: '#9ca3af', // Tailwind's gray-400
    ':hover': {
      backgroundColor: '#6b7280', // Tailwind's gray-600
      color: 'white',
    },
  }),
};

export default function MultiSelect({ control, name, label, data, multiselect, defaultValue }) {
  const [options, setOptions] = useState([]);

  useEffect(() => {
    const loadOptions = async() => {
      setOptions(data);
    };
    loadOptions();
  }, [data]);


  return (
    <div className="mb-4 col-span-8">
      <label className="block text-sm font-medium text-gray-700 mb-2">{label}</label>
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, name, ref } }) => (
          <Select
            name={name}
            ref={ref}
            options={options}
            isMulti={multiselect}
            onChange={onChange}
            styles={customStyles}
            defaultValue={defaultValue}
            className="shadow-sm focus:ring-primary focus:border-primary block w-full sm:text-sm border-gray-300 rounded-md"
            placeholder="Auswählen..."
          />
        )}
      />
    </div>
  );
};
