import useSWR from "swr";
import fetcher from "../../lib/fetcher";
import {useDispatch} from "react-redux";

import AdminLayout from "../../components/Layout/AdminLayout";
import Table from "../../components/Table/Table";
import Spinner from "../../components/Spinner";
import Modal from "../../components/Modal";
import Error from "../../components/Error";

import {CheckIcon, PencilIcon, TrashIcon, XIcon} from "@heroicons/react/outline";


import {deleteUvm} from "../../actions/uvmActions";
import UvmForm from "../../components/Forms/UvmForm";
import {useState} from "react";
import {date} from "../../lib/date";
import {Link} from "react-router-dom";

export default function Uvm(props) {
  const { data, error, mutate } = useSWR("/api/uvm", fetcher);
  const [edit, setEdit] = useState(false);
  const dispatch = useDispatch();

  const deleteClick = (id) => {
    dispatch(deleteUvm(id, props.history));
    mutate();
  };

  const columns = [
    {Header: "Sorte", accessor: "sorte"},
    {Header: "Hallertau", accessor: "deh"},
    {Header: "Tettnang", accessor: "det"},
    {Header: "Spalt", accessor: "des"},
    {Header: "Elbe / Saale", accessor: "dee"},
    {Header: "KG Alpha", accessor: "kg_alpha", Cell: ({value}) => value ? <CheckIcon className="block h-6 w-6 text-primary" aria-hidden="true" /> : <XIcon className="block h-6 w-6 text-red-500" aria-hidden="true" />},
    {Header: "Erstellt am", accessor: "created_at", Cell: ({value}) => date(value)},
    {Header: "Aktualisiert am", accessor: "updated_at", Cell: ({value}) => date(value)},
    {
      accessor: "id",
      Cell: (props) => (
        <Link to={`/admin/uvm/${props.value}`}>
          <PencilIcon
            className="block h-6 w-6 hover:text-primary"
            aria-hidden="true"
          />
        </Link>
      ),
    },
    {
      accessor: "delete",
      Cell: (props) => (
        <Modal icon={<TrashIcon className="block h-6 w-6 hover:text-primary" aria-hidden="true" />}
          modalAction={() =>
            deleteClick(props.cell.row.original.id)
          }
          modalText="UVM wirklich löschen?"
          modalButton="Löschen"
        />
      ),
    },
  ];

  if (error) return <Error error={error} />;
  if (!data) return <Spinner />;

  return (
    <AdminLayout>
      {edit
        ? (<UvmForm {...props} defaultValues={{}} edit={edit} />)
        : (
          <Table
            button={() => setEdit(true)}
            buttonLabel="UVM hinzufügen"
            search={true}
            title="UVM"
            data={data.data}
            columns={columns}
          />)}
    </AdminLayout>)
}
