function WeatherCard({ day }) {

  const icon = day.weather[0].icon;
  const maxTemp = day.temp.max.toFixed() + "℃";
  const minTemp = day.temp.min.toFixed() + "℃";
  const rain = (day.rain ? day.rain : 0) + ' mm';
  const wind = (day.wind_speed ? day.wind_speed.toLocaleString('de-DE') : 0) + ' m/s';
  const humidity = day.humidity + ' %';
  const desc = " (" + day.weather[0].description + ")";
  const date =  new Date(day.dt * 1000).toLocaleDateString("de-DE", {
        day: "numeric",
        month: "short",
    });

  return (
    <div className=" relative rounded-lg border border-gray-300 bg-white px-6 py-5 shadow-sm flex items-center space-x-3 hover:border-gray-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500">
      <div className="flex-shrink-0">
        <img
          width="50"
          src={`https://openweathermap.org/img/wn/${icon}.png`}
          alt={icon}
        />
      </div>
      <div className="flex-1 min-w-0">
        <span className="absolute inset-0" aria-hidden="true" />
        <p className="text-sm font-medium text-gray-900">{date} {desc}</p>
        <div className="text-sm text-gray-500 truncate">
          <p><strong>{maxTemp}</strong> | {minTemp}</p>
          <p>Luftfeuchtigkeit: {humidity}</p>
          <p>Niederschlag: {rain}</p>
          <p>Windgeschwindigkeit: {wind}</p>
        </div>
      </div>
    </div>
  );
}

export default WeatherCard;
