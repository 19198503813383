import axios from "axios";

export const addPriceList = (values, history) => async(dispatch) => {
  const res = await axios.post("/api/price-list", values);
  dispatch({ type: "GET_PRICE_LIST", payload: res.data });
  history.push("/admin/preislisten");
};

export const updatePriceList = (id, values, history) => async(dispatch) => {
  const res = await axios.patch(`/api/price-list/${id}`, values);
  dispatch({ type: "GET_PRICE_LIST", payload: res.data });
  history.push("/admin/preislisten");
};

export const updatePriceListStatus = (id, values, history) => async(dispatch) => {
  const res = await axios.patch(`/api/price-list/status/${id}`, values);
  dispatch({ type: "GET_PRICE_LIST", payload: res.data });
  history.push("/admin/preislisten");
};

export const deletePriceList = (id, history) => async(dispatch) => {
  const res = await axios.delete(`/api/price-list/${id}`);
  dispatch({ type: "GET_PRICE_LIST", payload: res.data });
  history.push("/admin/preislisten");
};
