export default function formatPricelist(contract) {
  switch (contract.Preisliste) {
    case 0:
      return "Allgemeine Preisliste";
    case 1:
      return "Ausgewählte Preisliste";
    case 2:
      return `Individuell (${contract.permissions[0] ? contract.permissions[0].growerId : ''})`;
    case 3:
      return "Deaktiviert";

    default:
      return "-";
  }
}
