import axios from "axios";

import Vertragsbestandteile from "../Angebote/Vertragsbestandteile";
import Zusatzvereinbarungen from "../Angebote/Zusatzvereinbarungen";
import Wirksamkeit from "../Angebote/Wirksamkeit";
import AGB from "../Angebote/AGB";
import Bezahlung from "../Angebote/Bezahlung";
import ContractApproval from "../Angebote/ContractApproval";

import KaufpreisCheck from "./KaufpreisCheck";
import Card from "../Shop/Card";

import { useForm, FormProvider } from "react-hook-form";
import { getRange } from "../../lib/getRange";

export default function Check({ items, setSuccess }) {
  const { startUvm, angebot, raw, data } = items;
  const methods = useForm();
  const url = `angebot?vertragsart=${angebot.Vertragsart}&jahr=${angebot.Startjahr}&sorte=${angebot.Sorte}&id=${angebot.id}`;

  const unit = raw ? "kg" : "kg Alpha";
  const fullUnit = unit === "kg" ? "kg Rohhopfen" : "kg Alpha";

  const duration = getRange(data.beginn, data.ende);
  const uvm = new Array(duration.length).fill(startUvm);
  const prices = angebot.preisliste;

  const onSubmit = async (values) => {
    const data = { angebot: items.data, params: items.angebot };
    await axios.post("/api/contracts/vorvertrag", data);
    setSuccess(true);
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <h3 className="font-display text-3xl uppercase text-gray-900">
          Ihr Vertragsangebot prüfen
        </h3>
        <Vertragsbestandteile />

        <Card title="II. Lieferzeitraum, Menge, Grundpreis">
          <p>
            Der Verkäufer verpflichtet sich, dem Käufer im jeweiligen Erntejahr
            amtlich bezeichneten{" "}
            <b>
              Rohhopfen ausschließlich aus dem Ertrag der innerhalb seines
              eigenen Betriebes bewirtschafteten Hopfenanbaufläche
            </b>{" "}
            wie folgt zu liefern:
          </p>
          <br />
          <p>
            <b>Sorte:</b> {angebot.hop.Beschreibung}
          </p>
          <div className="flex flex-col mt-5">
            <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                <div className="overflow-hidden  sm:rounded-lg">
                  <table className="angebot min-w-full divide-y divide-gray-200">
                    <tbody className="bg-white divide-y divide-gray-200">
                      <tr>
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                          Lieferjahre:
                        </td>
                        {duration.map((year, idx) => (
                          <td
                            className="px-6 py-4 whitespace-nowrap text-sm text-gray-500"
                            key={idx}
                          >
                            {year}
                          </td>
                        ))}
                      </tr>
                      <tr>
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                          Vertragsmenge {unit}:
                        </td>
                        {data.liefermenge.map((menge, idx) => (
                          <td
                            className="px-6 py-4 whitespace-nowrap text-sm text-gray-500"
                            key={idx}
                          >
                            {Number(menge).toLocaleString("de-DE")}
                          </td>
                        ))}
                      </tr>
                      <tr>
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                          {unit === "kg Alpha"
                            ? "Festpreis €/kg Alpha"
                            : "Grundpreis €/kg"}
                          :
                        </td>
                        {prices.slice(0, duration.length).map(({ price }, idx) => (
                          <td
                            className="px-6 py-4 whitespace-nowrap text-sm text-gray-500"
                            key={idx}
                          >
                            {Number(price)
                              .toFixed(2)
                              .toString()
                              .replace(".", ",")}
                          </td>
                        ))}
                      </tr>
                      <tr>
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-500">
                          netto, zzgl. gesetzl. MWSt.
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </Card>
        <Card
          title={`Sonstige Lieferverpflichtungen der Vertragssorte in ${fullUnit}`}
        >
          (Alle bei Vertragsabschluss bestehenden Lieferverträge der
          Vertragssorte mit Firmen, Pacht-, Austragsverpflichtungen usw.)
          {data.firma ? (
            <div className="mt-5 flex flex-col">
              <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                  <div className="overflow-hidden  sm:rounded-lg">
                    <table className="angebot min-w-full divide-y divide-gray-200">
                      <tbody className="bg-white divide-y divide-gray-200">
                        {data.firma[0] && (
                          <tr>
                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                              {data.firma[0] === "Andere Firma"
                                ? data.other[0]
                                : data.firma[0] || null}{" "}
                              ({unit})
                            </td>
                            {data.menge[0].map((menge, idx) => (
                              <td
                                className="px-6 py-4 whitespace-nowrap text-sm text-gray-500"
                                key={idx}
                              >
                                {Number(menge).toLocaleString("de-DE")}
                              </td>
                            ))}
                          </tr>
                        )}
                        {data.firma[1] && (
                          <tr>
                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                              {data.firma[1] === "Andere Firma"
                                ? data.other[1]
                                : data.firma[1] || null}{" "}
                              ({unit})
                            </td>
                            {data.menge[1].map((menge, idx) => (
                              <td
                                className="px-6 py-4 whitespace-nowrap text-sm text-gray-500"
                                key={idx}
                              >
                                {Number(menge).toLocaleString("de-DE")}
                              </td>
                            ))}
                          </tr>
                        )}
                        {data.firma[2] && (
                          <tr>
                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                              {data.firma[2] === "Andere Firma"
                                ? data.other[2]
                                : data.firma[2] || null}{" "}
                              ({unit})
                            </td>
                            {data.menge[2].map((menge, idx) => (
                              <td
                                className="px-6 py-4 whitespace-nowrap text-sm text-gray-500"
                                key={idx}
                              >
                                {Number(menge).toLocaleString("de-DE")}
                              </td>
                            ))}
                          </tr>
                        )}
                        <tr>
                          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                            Lieferverpflichtungen gesamt ({unit}):
                          </td>
                          {data.menge[0].map((menge, idx) => {
                            const verpflichtungSumme =
                              Number(data.menge[0] ? data.menge[0][idx] : 0) +
                              Number(data.menge[1] ? data.menge[1][idx] : 0) +
                              Number(data.menge[2] ? data.menge[2][idx] : 0) +
                              Number(data.liefermenge[idx]);

                            return (
                              <td
                                className="px-6 py-4 whitespace-nowrap text-sm text-gray-500"
                                key={idx}
                              >
                                {verpflichtungSumme.toLocaleString("de-DE")}
                              </td>
                            );
                          })}
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <p className="mt-5 ml-5">Keine weiteren Lieferverpflichtungen</p>
          )}
        </Card>
        <Card title="III. Leistungsfähigkeit des Verkäufers">
          <p>
            Die Leistungsfähigkeit des Verkäufers bestimmt sich nach Maßgabe der
            AVHLV, Ziffer II. und Anhang I. (UVM). Der Verkäufer betreibt zum
            Zeitpunkt des Vertragsabschlusses in seinem eigenen Betrieb
            folgenden Hopfenanbau in der dem Vertrag zu Grunde liegenden Sorte:{" "}
          </p>
          <div className="flex flex-col mt-5">
            <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                <div className="overflow-hidden  sm:rounded-lg">
                  <table className="angebot min-w-full divide-y divide-gray-200">
                    <tbody className="bg-white divide-y divide-gray-200">
                      <tr>
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                          Altfläche ha:
                        </td>
                        {data.ha.map((ha, idx) => (
                          <td
                            className="px-6 py-4 whitespace-nowrap text-sm text-gray-500"
                            key={idx}
                          >
                            {Number(ha).toFixed(2).toString().replace(".", ",")}
                          </td>
                        ))}
                      </tr>
                      <tr>
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                          Unbedenkliche Vertragsmenge (UVM) {fullUnit} je ha:
                        </td>
                        {uvm.map((value, idx) => (
                          <td
                            className="px-6 py-4 whitespace-nowrap text-sm text-gray-500"
                            key={idx}
                          >
                            {Number(value)
                              .toFixed(0)
                              .toString()
                              .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")}{" "}
                          </td>
                        ))}
                      </tr>
                      <tr>
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                          UVM {fullUnit} gesamt = UVM je ha x Altfläche ha
                        </td>
                        {data.ha.map((ha, idx) => (
                          <td
                            className="px-6 py-4 whitespace-nowrap text-sm text-gray-500"
                            key={idx}
                          >
                            {Number(ha * startUvm)
                              .toFixed(0)
                              .toString()
                              .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")}
                          </td>
                        ))}
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </Card>
        <br />

        <KaufpreisCheck raw={raw} angebot={angebot} data={data} />
        <Bezahlung check={true} raw={raw} data={data} />
        <ContractApproval check={true} raw={raw} data={data} />
        <Card title="VII. Bindung an Vertragsangebot">
          <p>
            Soweit in diesem Vertrag nichts anderes vorrangig geregelt ist,
            gelten die Bestimmungen nach Ziffer XI. und XII. der AVHLV.
          </p>
        </Card>
        <Zusatzvereinbarungen
          title="VIII. Zusatzvereinbarungen"
          angebot={angebot}
        />
        <Wirksamkeit title="IX. Wirksamkeit des Vertrages – Geltung AVHLV" />
        <AGB />
        <br />

        <div className="pt-5">
          <div className="flex justify-end">
            <a
              href={url}
              className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-secondary"
            >
              Ihr Angebot korrigieren
            </a>
            <button
              type="submit"
              className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-primary hover:bg-secondary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-secondary"
            >
              Ihr Vertragsangebot verbindlich absenden
            </button>
          </div>
        </div>
      </form>
    </FormProvider>
  );
}
