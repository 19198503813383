import {FormProvider, useForm} from "react-hook-form-new";

import Input from "../Input/v2/Input";
import Select from "../Input/v2/Select";
import Radio from "../Input/v2/RadioGroup";
import Checkbox from "../Input/v2/Checkbox";
import MultiSelect from "../Input/v2/MultiSelect";

import {areaOptions} from "../../utils/area-options";
import {contractOptions} from "../../utils/contract-options"
import {qualityOptions} from "../../utils/quality-options"
import {freeContractOptions} from "../../utils/free-contract-options"
import {priceListOptions} from "../../utils/price-list-options";
import {deliveryOptions} from "../../utils/delivery-options";

import {useEffect} from "react";
import {getRange} from "../../lib/getRange";
import useSWR from "swr";
import {addPriceList, updatePriceList} from "../../actions/priceListActions";
import {useDispatch} from "react-redux";
import fetcher from "../../lib/fetcher";
import Spinner from "../Spinner";
import getDefaultGrower from "../../lib/getDefaultGrower";

import {yupResolver} from "@hookform/resolvers/yup"
import * as yup from "yup";

const schema = yup
.object({
  quality: yup
  .string()
  .when('delivery_type', {
    is: (val) => val === 1,
    then: (schema) => schema.required('Bitte Qualität auswählen.').typeError('Bitte Qualität auswählen.'),
    otherwise: (schema) => schema
  }),
  variety: yup.string().required('Bitte Sorte auswählen.').typeError('Bitte Sorte auswählen.'),
  contract_type: yup.string().required().required('Bitte Vertragsart auswählen.').typeError('Bitte Vertragsart auswählen.'),
  delivery_type: yup.string().required().required('Bitte Lieferart auswählen.').typeError('Bitte Lieferart auswählen.'),
  price_list_type: yup.number().required('Bitte Preislistenart auswählen.').typeError('Bitte Preislistenart auswählen.')
})
.required()

export default function PriceListForm(props) {
  const methods = useForm({ resolver: yupResolver(schema) });
  const { errors } = methods.formState;

  const dispatch = useDispatch();
  const { edit, setEdit, defaultValues, grower, cropYear, mutate } = props;
  const { Alpha, contract_type, end_year, price_list_type, start_year, delivery_type } = methods.watch();
  const yearRange = getRange(start_year, end_year);
  const defaultGrower = getDefaultGrower(defaultValues, grower);

  useEffect(() => {
    if (defaultValues) {
      if (defaultValues.prices.length > 0) {
        defaultValues.prices.map(({ price }, idx) => {
          return methods.setValue(`prices[${idx}]`, price);
        })
      } else if (defaultValues.price_kg_alpha || defaultValues.price_kg) {
        methods.setValue('price_kg_alpha', defaultValues.price_kg_alpha);
        methods.setValue('price_kg', defaultValues.price_kg);
      }

      if (defaultValues.permissions.length > 0) {
        methods.setValue('isValidFor', defaultGrower)
      }
    }
  }, [])

  const onSubmit = (data) => {
    edit
      ? dispatch(addPriceList(data, props.history)) && setEdit(false) && mutate()
      : dispatch(updatePriceList(props.match.params.id, data, props.history));
  };

  const { data, error } = useSWR("/api/admin/sorten", fetcher);
  if (error) return <div>failed to load</div>;
  if (!data) return <Spinner />;


  const varieties = data.data.map((sorten) => ({
    value: sorten.Code,
    label: sorten.Beschreibung,
  }));

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <div className="bg-white px-4 py-5 border-b border-gray-200 sm:px-6">
          <h3 className="font-display uppercase text-lg leading-6 font-medium text-gray-900">
            {edit ? "Preisliste hinzufügen" : "Preisliste bearbeiten"}
          </h3>
        </div>
        <div className="bg-white px-4 py-5 border-b border-gray-200 sm:px-6">
          <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-8">
            <Select label="Sorte *" name="variety" defaultValue={defaultValues.variety} options={varieties} grid="sm:col-span-2" error={errors.variety?.message} />
            <Select label="Vertragsart *" name="contract_type" defaultValue={defaultValues.contract_type} options={contractOptions} grid="sm:col-span-2" error={errors.contract_type?.message} />
            <Select label="Lieferart *" name="delivery_type" defaultValue={defaultValues.delivery_type} options={deliveryOptions} grid="sm:col-span-2" error={errors.delivery_type?.message} />

            {(Boolean(delivery_type === 0) || (defaultValues.delivery_type === 0)) &&
              <>
                <Select label="Qualität *" name="quality" defaultValue={defaultValues ? defaultValues.quality : 0} options={qualityOptions} grid="sm:col-span-2" error={errors.quality?.message} />
                <Checkbox options={[{ label: 'Alpha', value: 1 }]} defaultValue={{ 'Alpha': defaultValues.alpha }} />
              </>
            }

            {(Boolean(contract_type === 0 || defaultValues.contract_type === 0) && (Alpha || defaultValues.alpha)) &&
              <>
                <Input type="number" name="alpha_from" label="Alpha von" defaultValue={defaultValues.alpha_from} decimal={true} grid="sm:col-span-2" />
                <Input type="number" name="alpha_to" label="Alpha bis" defaultValue={defaultValues.alpha_to} decimal={true} grid="sm:col-span-2" />
              </>
            }

            {(delivery_type !== undefined && Boolean(contract_type !== 0)) &&
              <>
                {Boolean(delivery_type === 1) && <Input type="number" name="price_kg_alpha" decimal={true} label="Preis KG Alpha" grid="sm:col-span-2" required={true} />}
                {Boolean(delivery_type === 0) && <Input type="number" name="price_kg" decimal={true} label="Preis KG" grid="sm:col-span-3" />}
              </>
            }
            {Boolean(contract_type === 0 || defaultValues.contract_type === 0) && <>
              <div className="col-span-8">
                <Input type="number" defaultValue={defaultValues.start_year} name="start_year" label="Startjahr" grid="sm:col-span-2" required={true}
                       validation={{
                         min: {
                           value: cropYear, message: "Startjahr muss gleich oder größer als das Erntejahr sein."
                         }
                       }}
                />
                <Input type="number" defaultValue={defaultValues.end_year} name="end_year" label="Endjahr" grid="sm:col-span-2" required={true} />
                <Input type="number" defaultValue={defaultValues.latest_start} name="latest_start" label="Beginn spätestens" grid="sm:col-span-2" required={true} />
                <Input type="number" defaultValue={defaultValues.minimum_term} name="minimum_term" label="Mindestlaufzeit" grid="sm:col-span-2" required={true} />
              </div>

              {((yearRange.length > 1)) && yearRange.map((year, idx) => {
                return (
                  <div key={idx}>
                    <label htmlFor={`prices[${idx}]`} className="block text-sm font-medium text-gray-700">{year}</label>
                    <input
                      className="mt-5 focus:ring-primary focus:border-primary block w-full sm:text-sm border-gray-300 rounded-md"
                      type="number"
                      step="0.01"
                      placeholder="0,00"
                      {...methods.register(`prices[${idx}]`, {
                        required: true,
                        validate: (value) => value > 0,
                      })}
                    />
                  </div>
                )
              })}
            </>
            }

            {Boolean(contract_type === 1 || contract_type === 3) &&
              <div className="col-span-8">
                <Checkbox label="Freihopfen Lieferart"
                          options={freeContractOptions}
                          defaultValue={{
                            "Freihopfen Schätzmenge": defaultValues.free_contract_estimate,
                            "Freihopfen Menge bekannt": defaultValues.free_contract_known,
                            "Freihopfen Überlieferung": defaultValues.free_contract_delivery
                          }}
                />
                {errors.price_list_type?.message}
              </div>
            }

            <div className="col-span-8">
              <Radio label="Preisliste gültig für:" name="price_list_type" options={priceListOptions} defaultValue={Number(defaultValues.pricelist_type)} />
            </div>

            {(Boolean(Number(price_list_type) === 0) || defaultValues.pricelist_type === '0') &&
              <div className="col-span-full">
                <Checkbox options={areaOptions} label="Gültig für:" defaultValue={
                  {
                    Hallertau: defaultValues.area_deh,
                    "Elbe Saale": defaultValues.area_dee,
                    Spalt: defaultValues.area_des,
                    Tettnang: defaultValues.area_det
                  }
                } />
              </div>
            }

            {(Boolean(Number(price_list_type) === 1) || defaultValues.pricelist_type === '1') &&
              <MultiSelect control={methods.control} defaultValue={defaultGrower} name="isValidFor" multiselect={true} label="Gültig für die Betriebe:" data={grower} />
            }
            {(Boolean(Number(price_list_type) === 2) || defaultValues.pricelist_type === '2') &&
              <Select label="Gültig für den Betrieb:" name="isValidFor" defaultValue={defaultValues && defaultValues.permissions.length > 0 && (defaultValues.permissions[0].growerId)} options={grower} />
            }

            <div className="col-span-8">
              <Checkbox options={[{ label: 'Variante gültig', value: 1 }]} defaultValue={{ 'Variante gültig': defaultValues.is_valid }} />
            </div>

            <Input name="additional_1" label="Zusatzvereinbarung I" />
            <Input name="additional_2" label="Zusatzvereinbarung II" />
            <Input name="additional_3" label="Zusatzvereinbarung III" />
          </div>
        </div>
        <div className="pt-5">
          <div className="flex justify-end">
            <a href="/admin/preislisten" className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-secondary">
              Zurück
            </a>
            <button
              type="submit"
              className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-primary hover:bg-secondary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-secondary"
            >
              Speichern
            </button>
          </div>
        </div>
      </form>
    </FormProvider>
  )
    ;
}
