import {Link} from "react-router-dom";
import {currency} from "../../lib/currency";
import {formatContract} from "../../lib/formatContract";
import formatPricelist from "../../lib/formatPricelist";
import formatQuality from "../../lib/formatQuality";

export default function TableCellState({value, type}) {
  switch (type) {
    case 'contract':
      return formatContract(value);
    case 'pricelist':
      return formatPricelist(value);
    case 'price':
      return value.prices.length > 0 ? 'Preisliste' : value.Lieferart === 0 ? `${currency(value['Preis Kg'])} / kg` : `${currency(value['Preis Kg Alpha'])} / kg Alpha`;
    case 'alpha':
      return value > 0 ? 'Alpha' : '-';
    case 'delivery':
      return value === 0 ? 'kg Rohhopfen' : 'kg Alpha';
    case 'quality':
      return formatQuality(value);
    case 'link':
      return (<Link className='hover:text-primary' target='_blank' rel='noopener noreferrer' to={value}>{value}</Link>);
    default:
      return "";
  }
}
