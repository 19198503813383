export default function getDefaultGrower(defaultValues, grower) {
  if (defaultValues.permissions) {
    if (defaultValues.permissions.length === 1) {
      return grower.filter((grower) => grower.value === (defaultValues.permissions[0].growerId))
    } else if (defaultValues.permissions.length > 1) {
      const defaultGrowerIds = [];

      defaultValues.permissions.map((record) => {
        return defaultGrowerIds.push(record.growerId)
      })

      return grower.filter((item) => {
        return defaultGrowerIds.includes(item.value)
      });
    }
  }
}
