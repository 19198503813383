import useSWR from "swr";
import fetcher from "../lib/fetcher";

export default function useVarieties() {

  const { data, error } = useSWR("/api/admin/sorten", fetcher);
  if (error) return [];
  if (!data) return "Loading...";

  return data.data.map((sorten) => ({
    value: sorten.Code,
    label: sorten.Beschreibung,
  }));

}
