import useSWR from "swr";
import fetcher from "../../lib/fetcher";

import AdminLayout from "../../components/Layout/AdminLayout";
import Spinner from "../../components/Spinner";
import Error from "../../components/Error";

import PriceListForm from "../../components/Forms/PriceListForm";
import useVarieties from "../../hooks/useVarities";
import useGrower from "../../hooks/useGrower";

export default function PriceListEdit(props) {
  const varieties = useVarieties();
  const grower = useGrower();
  const { data, error } = useSWR(`/api/price-list/${props.match.params.id}`, fetcher);

  if (error) return <Error error={error} />;
  if (!data) return <Spinner />;

  const defaultValues = {
    additional_1: data.data['Zusatzvereinbarung Zeile 1'],
    additional_2: data.data['Zusatzvereinbarung Zeile 2'],
    additional_3: data.data['Zusatzvereinbarung Zeile 3'],
    alpha: !!data.data['Alpha von'],
    alpha_from: data.data['Alpha von'],
    alpha_to: data.data['Alpha bis'],
    area_deh: data.data['Gültig für 1'] === "1" && 'DEH',
    area_det: data.data['Gültig für 2'] === "1" && 'DET',
    area_dee: data.data['Gültig für 3'] === "1" && 'DEE',
    area_des: data.data['Gültig für 4'] === "1" && 'DES',
    contract_type: data.data.Vertragsart,
    prices: data.data.prices,
    permissions: data.data.permissions,
    price_kg: data.data['Preis Kg'],
    price_kg_alpha: data.data['Preis Kg Alpha'],
    start_year: data.data.Startjahr,
    end_year: data.data.Endjahr,
    variety: data.data.Sorte,
    latest_start: data.data['Beginn spätestens'],
    minimum_term: data.data.Mindestlaufzeit,
    delivery_type: data.data.Lieferart,
    free_contract_delivery: data.data['Freihopfen (Aus Überlieferung)'],
    free_contract_estimate: data.data['Freihopfen (Schätzmenge)'],
    free_contract_known: data.data['Freihopfen (Menge bekannt)'],
    pricelist_type: data.data.Preisliste,
    quality: data.data['Qualität'],
    is_valid: data.data['Variante gültig']
  }

  return (
    <AdminLayout>
      <PriceListForm {...props} defaultValues={defaultValues} varieties={varieties} grower={grower} />
    </AdminLayout>
  );
}
