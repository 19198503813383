import useSWR from "swr";
import fetcher from "../lib/fetcher";

export default function useGrower() {
  const { data, error } = useSWR("/api/admin/grower", fetcher);
  if (error || !data) return [];
  
  return data.data.map((grower) => ({
    value: grower.growerNumber,
    label: grower.name,
  }));
}
