import { useFormContext } from "react-hook-form-new";
import { classNames } from "../../../lib/classNames";


export default function Input({label, name, type, placeholder, required, grid, decimal, validation, defaultValue}) {
  const {register} = useFormContext(); // retrieve all hook methods
  const step = type === 'number' && decimal ? 'any' : undefined;

  return (
    <div className={classNames(grid ? grid : 'col-span-full', "mt-2")}>
      {label
        ? (<label htmlFor={name} className="block text-sm font-medium text-gray-700 mb-2">{label}</label>)
        : (<label htmlFor={name} className="sr-only">{name}</label>
        )}
      <input
        className="shadow-sm focus:ring-primary focus:border-primary block w-full sm:text-sm border-gray-300 rounded-md"
        placeholder={placeholder}
        step={step}
        defaultValue={defaultValue}
        type={type ? type : "text"}
        {...register(name, {required: required, ...validation})}
      />
    </div>
  );
}
