import { useEffect } from "react";

export default function Freihopfen({ register, setValue, angebot, art }) {
  const typeArray = [
    angebot["Freihopfen (Schätzmenge)"],
    angebot["Freihopfen (Menge bekannt)"],
    angebot["Freihopfen (Aus Überlieferung)"],
  ];

  const checkDefaultType = typeArray.toString() === [0, 0, 1].toString();

  useEffect(() => {
    if (checkDefaultType) {
      setValue("freihopfen", "2");
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div className="bg-white shadow overflow-hidden sm:rounded-lg">
      <div className="px-4 py-5 sm:px-6">
        <h3 className="text-lg leading-6 font-medium text-gray-900">{art}</h3>
      </div>

      <div className="border-t border-primary px-4  sm:px-6" />
      {Boolean(angebot["Freihopfen (Schätzmenge)"]) && (
        <div className="relative border p-4 flex flex-col cursor-pointer md:pl-4 md:pr-6 md:grid md:grid-cols-2 focus:outline-none">
          <label>
            <input
              name="freihopfen"
              className="text-primary"
              type="radio"
              ref={register({ required: true })}
              value={0}
            />
            <span className="ml-4 font-medium"> {art} (Schätzmenge)</span>
          </label>
        </div>
      )}
      {Boolean(angebot["Freihopfen (Menge bekannt)"]) && (
        <div className="relative border p-4 flex flex-col cursor-pointer md:pl-4 md:pr-6 md:grid md:grid-cols-3 focus:outline-none">
          <label>
            <input
              name="freihopfen"
              className="text-primary"
              type="radio"
              ref={register({ required: true })}
              value={1}
            />
            <span className="ml-4 font-medium">{art} (Menge bekannt)</span>
          </label>
        </div>
      )}
      {Boolean(angebot["Freihopfen (Aus Überlieferung)"]) && (
        <div className="relative border p-4 flex flex-col cursor-pointer md:pl-4 md:pr-6 md:grid md:grid-cols-3 focus:outline-none">
          <label>
            <input
              name="freihopfen"
              className="text-primary"
              type="radio"
              ref={register({ required: true })}
              value={2}
            />
            <span className="ml-4 font-medium">{art} (aus Überlieferung)</span>
          </label>
        </div>
      )}
    </div>
  );
}
