import axios from "axios";

import { useForm, FormProvider } from "react-hook-form";
import useSystem from "../../hooks/useSystem";
import useLieferung from "../../hooks/useLieferung";

import { calcSum } from "../../lib/calcSum";
import { formatKilo } from "../../lib/formatKilo";
import { getHopType } from "../../lib/getHopType";
import { getFixedPrice } from "../../lib/getFixedPrice";

import AGB from "../Angebote/AGB";
import Vertragsbestandteile from "../Angebote/Vertragsbestandteile";
import Zusatzvereinbarungen from "../Angebote/Zusatzvereinbarungen";
import Bezahlung from "../Angebote/Bezahlung";
import Kaufpreisberechnung from "./Kaufpreisberechnung";

export default function Check({ items, setSuccess }) {
  const methods = useForm();
  const { data, angebot } = items;
  const url = `angebot?vertragsart=${angebot.Vertragsart}&jahr=${angebot.Startjahr}&sorte=${angebot.Sorte}&id=${angebot.id}`;

  const type = getHopType(angebot);
  const price = getFixedPrice(angebot);
  const unit = type === " kg Rohhopfen" ? "kg" : "kg Alpha";
  const raw = type === " kg Rohhopfen" ? true : false;

  const { cropYear } = useSystem();
  const lieferung = useLieferung(angebot.Sorte);
  const menge =
    Number(data.freihopfen) === 0
      ? Number(data.estimate)
      : Number(data.freihopfen) === 1
      ? calcSum(data.amount, lieferung, data.überlieferung)
      : Number(data.freihopfen) === 2 && Number(lieferung);

  const buyerWeight = data.weight ? data.weight.filter((w) => w !== true) : 0;

  const onSubmit = async (values) => {
    const data = {
      angebot: items.data,
      params: items.angebot,
      menge: menge,
      preis: price,
      type: type,
      lieferung:
        items.data.überlieferung || Number(items.data.freihopfen) === 2
          ? lieferung
          : 0,
      abwaage:
        Number(items.data.freihopfen) === 0 || buyerWeight.length > 0 ? 1 : 0,
    };

    await axios.post("/api/contracts/freihopfen", data);
    setSuccess(true);
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <Vertragsbestandteile />

        <div className="bg-white mt-6 shadow overflow-hidden sm:rounded-lg">
          <div className="px-4 py-5 sm:px-6">
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              II. Lieferzeitraum, Menge, Grundpreis
            </h3>
          </div>
          <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
            <div className="border-t border-primary px-4 py-5 sm:px-6">
              <div className="sm:col-span-2">
                <dd className="mt-1 text-gray-900">
                  Der Verkäufer verpflichtet sich, dem Käufer amtlich
                  bezeichneten{" "}
                  <b>
                    Rohhopfen des Erntejahres ausschließlich aus dem Ertrag der
                    innerhalb seines eigenen Betriebes bewirtschafteten
                    Hopfenanbaufläche
                  </b>{" "}
                  zu liefern:
                </dd>
              </div>
            </div>

            <dl className="sm:divide-y sm:divide-gray-200">
              <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">Sorte</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  {angebot.hop.Beschreibung}
                </dd>
              </div>
              <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">
                  Vertragsmenge
                </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  {Number(data.freihopfen) === 0 ? "ca. " : ""}
                  {formatKilo(menge)} kg Rohhopfen
                </dd>
              </div>
              <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">Erntejahr</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  {cropYear}
                </dd>
              </div>
              <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">
                  {type === " kg Rohhopfen" ? "Grundpreis" : "Festpreis"} /{" "}
                  {unit}
                </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  {price}
                </dd>
              </div>
            </dl>
          </div>
        </div>

        <div className="bg-white mt-6 shadow overflow-hidden sm:rounded-lg">
          <div className="px-4 py-5 sm:px-6">
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              III. Angaben zum Freihopfen
            </h3>
          </div>
          <div className="border-t border-primary ">
            {data.überlieferung || data.freihopfen === "2" ? (
              <>
                <dl className="sm:divide-y sm:divide-gray-200">
                  <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt className="text-sm font-medium text-gray-500">
                      Die Liefermenge stammt aus:
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2"></dd>
                  </div>
                </dl>
                <div className="relative border p-4 flex flex-col cursor-pointer md:pl-4 md:pr-6 md:grid md:grid-cols-2 focus:outline-none">
                  <label>
                    <input
                      className="text-primary"
                      type="radio"
                      checked={true}
                      readOnly
                    />
                    <span className="ml-4 font-medium"> Überlieferung</span>
                  </label>
                </div>
              </>
            ) : (
              ""
            )}

            {data.freihopfen !== "2" ? (
              <dl className="sm:divide-y sm:divide-gray-200">
                <dt className="py-4 sm:px-6 text-sm font-medium text-gray-500">
                  Die Abwaage des Hopfens erfolgt / erfolgte auf
                </dt>
                {buyerWeight.length > 0 || data.freihopfen === "0" ? (
                  <div className="relative border p-4 flex flex-col cursor-pointer md:pl-4 md:pr-6 md:grid md:grid-cols-2 focus:outline-none">
                    <label>
                      <input
                        className="text-primary"
                        type="radio"
                        checked={true}
                        readOnly
                      />
                      <span className="ml-4 font-medium">
                        Namen des Käufers
                      </span>
                    </label>
                  </div>
                ) : (
                  ""
                )}
                {buyerWeight.length > 0 &&
                  data.weight.map((ballen, idx) =>
                    ballen !== true ? (
                      <div
                        key={idx}
                        className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
                      >
                        <dt className="text-sm font-medium text-gray-500">
                          {idx + 1}. Partie
                        </dt>
                      </div>
                    ) : (
                      ""
                    )
                  )}
                {data.partie ? (
                  <div className="relative border p-4 flex flex-col cursor-pointer md:pl-4 md:pr-6 md:grid md:grid-cols-2 focus:outline-none">
                    <label>
                      <input
                        className="text-primary"
                        type="radio"
                        checked={true}
                        readOnly
                      />
                      <span className="ml-4 font-medium">
                        "Käufer unbekannt"
                      </span>
                    </label>
                  </div>
                ) : (
                  ""
                )}
                {data.partie &&
                  data.partie.length > 0 &&
                  data.partie.map((ballen, idx) => (
                    <div
                      key={idx}
                      className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
                    >
                      <dt className="text-sm font-medium text-gray-500">
                        Partie {idx + 1} - 1. Ballennummer:
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        {ballen}
                      </dd>
                    </div>
                  ))}
              </dl>
            ) : (
              ""
            )}
          </div>
        </div>
        <br />
        <Kaufpreisberechnung
          title={
            raw
              ? "IV. Kaufpreisberechnung"
              : "IV. Ermittlung des Alphawertes und Kaufpreisberechnung"
          }
          angebot={angebot}
          type={type}
        />
        <Bezahlung check={true} raw={raw} data={data} />
        <Zusatzvereinbarungen
          title="VI. Zusatzvereinbarungen"
          angebot={angebot}
        />
        <AGB />
        <div className="pt-5">
          <div className="flex justify-end">
            <a
              href={url}
              className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-secondary"
            >
              Ihr Angebot korrigieren
            </a>
            <button
              type="submit"
              className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-primary hover:bg-secondary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-secondary"
            >
              Ihr Vertragsangebot verbindlich absenden
            </button>
          </div>
        </div>
      </form>
    </FormProvider>
  );
}
