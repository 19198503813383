import Input from "../Input/Input";
import Select from "../Input/Select";
import {useDispatch} from "react-redux";
import {FormProvider, useForm} from "react-hook-form";
import Checkbox from "../Input/v2/Checkbox";
import useSWR from "swr";
import fetcher from "../../lib/fetcher";
import Spinner from "../Spinner";
import {addUvm, updateUvm} from "../../actions/uvmActions";
import {useHistory} from "react-router-dom";

export default function UvmForm({ defaultValues, edit, match }) {
  const dispatch = useDispatch();
  const history = useHistory();

  const methods = useForm({
    defaultValues: defaultValues,
  })

  const onSubmit = (data) => {
    edit
      ? dispatch(addUvm(data, history))
      : dispatch(updateUvm(match.params.id, data, history));
  };

  const { data, error } = useSWR("/api/admin/sorten", fetcher);
  if (error) return <div>failed to load</div>;
  if (!data) return <Spinner />;


  const varieties = data.data.map((sorten) => ({
    value: sorten.Code,
    label: sorten.Beschreibung,
  }));


  return (

    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <div className="bg-white px-4 py-5 border-b border-gray-200 sm:px-6">
          <h3 className="font-display uppercase text-lg leading-6 font-medium text-gray-900">
            {edit ? "UVM hinzufügen" : "UVM bearbeiten"}
          </h3>
        </div>
        <div className="bg-white mb-3 px-4 py-5 border-b border-gray-200 sm:px-6">
          <Select label="Sorte" name="variety" options={varieties} defaultValue={defaultValues.sorte} />
          <Input label="Hallertau" name="deh" required={true} />
          <Input label="Tettnang" name="det" required={true} />
          <Input label="Spalt" name="des" required={true} />
          <Input label="Elbe / Saale" name="dee" required={true} />
          <Checkbox options={[{ value: 0, label: 'KG Alpha' }]} defaultValue={{ 'KG Alpha': defaultValues.kg_alpha }} />
        </div>
        <div className="pt-5">
          <div className="flex justify-end">
            <a href="/admin/uvm"
               className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-secondary"
            >
              Zurück
            </a>
            <button
              type="submit"
              className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-primary hover:bg-secondary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-secondary"
            >
              Speichern
            </button>
          </div>
        </div>
      </form>
    </FormProvider>
  );
}
