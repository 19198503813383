import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { formatKilo } from "../../lib/formatKilo";
import { ExclamationIcon } from "@heroicons/react/solid";

export default function FreihopfenÜberlieferung({ art, lieferung }) {
  // eslint-disable-next-line
  const [num, setNum] = useState(lieferung);

  useEffect(() => {
    setNum(lieferung);
  }, [lieferung]);

  return lieferung === 0 ? (
    <div className="bg-white mt-6 shadow overflow-hidden sm:rounded-lg">
      <div className="px-4 py-5 sm:px-6">
        <h3 className="text-lg leading-6 font-medium text-gray-900">
          {art}überlieferung
        </h3>
      </div>
      <div className="border-t border-primary" />
      <div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="mt-5 mb-5 md:mt-0 md:col-span-2">
            <div className="grid grid-cols-6 gap-6">
              <div className="col-span-6 sm:col-span-3">
                <label htmlFor="price" className="block text-sm font-medium text-gray-700">
                  {art}menge Kilogramm Rohhopfen
                </label>
                <div className="mt-1 relative rounded-md shadow-sm">
                  <input
                    value={formatKilo(num)}
                    type="text"
                    className="focus:ring-primary focus:border-primary block w-full pr-12 sm:text-sm border-gray-300 rounded-md"
                    aria-describedby="price-currency"
                    readOnly
                  />

                  <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                    <span className="text-gray-500 sm:text-sm" id="price-currency">kg</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {Boolean(lieferung === 0) && (
          <div className="mt-5 bg-yellow-50 border-l-4 border-yellow-400 p-4">
            <div className="flex">
              <div className="flex-shrink-0">
                <ExclamationIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
              </div>
              <div className="ml-3">
                <p className="text-sm text-yellow-700">
                  Uns liegen aktuell keine Überlieferungen für diese Sorte vor.
                  Bei Fragen wenden Sie sich bitte an {" "}
                  <Link className="underline text-yellow-800" to="/kontakt-einkauf">Ihren Einkäufer.</Link>
                </p>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  ) : (
    <div className="bg-white mt-6 shadow overflow-hidden sm:rounded-lg">
      <div className="px-4 py-5 sm:px-6">
        <h3 className="text-lg leading-6 font-medium text-gray-900">
          {art}überlieferung
        </h3>
      </div>
      <div className="border-t border-primary" />
      <div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="mt-5 mb-5 md:mt-0 md:col-span-2">
            <div className="grid grid-cols-6 gap-6">
              <div className="col-span-6 sm:col-span-3">
                <label htmlFor="price" className="block text-sm font-medium text-gray-700">
                  {art}menge Kilogramm Rohhopfen
                </label>
                <div className="mt-1 relative rounded-md shadow-sm">
                  <input
                    value={formatKilo(num)}
                    type="text"
                    className="focus:ring-primary focus:border-primary block w-full pr-12 sm:text-sm border-gray-300 rounded-md"
                    aria-describedby="price-currency"
                    readOnly
                  />
                  <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                    <span className="text-gray-500 sm:text-sm" id="price-currency">kg</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
