import useSWR from "swr";
import fetcher from "../../lib/fetcher";

import AdminLayout from "../../components/Layout/AdminLayout";
import Spinner from "../../components/Spinner";
import Error from "../../components/Error";

import UvmForm from "../../components/Forms/UvmForm";

export default function UvmEdit(props) {
  const { data, error } = useSWR(`/api/uvm/${props.match.params.id}`, fetcher);

  if (error) return <Error error={error} />;
  if (!data) return <Spinner />;

  const defaultValues = {
    dee: data.data.dee,
    deh: data.data.deh,
    des: data.data.des,
    det: data.data.det,
    kg_alpha: data.data.kg_alpha,
    sorte: data.data.sorte,
  }

  return (
    <AdminLayout>
      <UvmForm {...props} defaultValues={defaultValues} />
    </AdminLayout>
  );
}
